/**
 * initAccordion
 *
 * Accordion function for Description in PDS, for categories etc.
 * in Footer (only mobile view), for FAQ (desktop and mobile)
 */

import breakpoints from "@sharedJS/breakpoints";

// DOM
let accordionHeader;
let accordionContent;
let accordion;

let accordionHeaderMobile;
let accordionContentMobile;
let accordionMobile;

let accordionHeaderSubMobile;
let accordionContentSubMobile;

let headerNav;

let flagmup;
let flagmdown;

function init() {
	accordionHeader = $(".js-accordion-headline");
	accordionContent = $(".js-accordion-body");
	accordion = $(".js-accordion");

	accordionHeaderMobile = $(".js-accordion-headline-m");
	accordionContentMobile = $(".js-accordion-body-m");
	accordionMobile = $(".js-accordion-m");

	accordionHeaderSubMobile = $(".js-accordion-headline-sub-m");
	accordionContentSubMobile = $(".js-accordion-body-sub-m");

	headerNav = $(".js-header-nav");

	flagmup = true;
	flagmdown = true;

	const setViewPortPosition = false;

	//accordion for desktop view (PDS, FAQ)
	// gilt nicht mehr für PDS, FAQ
	if (accordion.length) {
		setAccordion(accordionHeader, accordionContent, setViewPortPosition);
		accordionHeader.filter(".is-open").slice(0, 1).next().css("display", "block");
	}

	//accordion for mobile view (FAQ, Footer)
	if (Modernizr.mq(breakpoints["l_down"])) {
		if (flagmdown !== false) {
			if (accordionMobile.length) {
				setAccordion(accordionHeaderMobile, accordionContentMobile, setViewPortPosition);
				setAccordion(accordionHeaderSubMobile, accordionContentSubMobile, setViewPortPosition);
				accordionHeaderMobile.filter(".is-open").slice(0, 1).next().css("display", "block");
				accordionHeaderSubMobile.filter(".is-open").slice(0, 1).next().css("display", "block");
			}
		}
		flagmdown = false;
		flagmup = true;
	} else {
		flagmup = false;
		flagmdown = true;
	}

	$(window).on("resize", () => {
		if (Modernizr.mq(breakpoints["l_down"])) {
			if (flagmdown !== false) {
				if (accordionMobile.length) {
					setAccordion(accordionHeaderMobile, accordionContentMobile, setViewPortPosition);
					setAccordion(accordionHeaderSubMobile, accordionContentSubMobile, setViewPortPosition);
					accordionHeaderMobile.filter(".is-open").slice(0, 1).next().css("display", "block");
					accordionHeaderSubMobile.filter(".is-open").slice(0, 1).next().css("display", "block");
				}
			}
			flagmdown = false;
			flagmup = true;
		} else {
			accordionHeaderMobile.off("click");
			if (flagmup !== false) {
				if (accordionMobile.length) {
					accordionContentMobile.removeAttr("style");
					accordionContentSubMobile.removeAttr("style");
				}
			}
			flagmup = false;
			flagmdown = true;
		}
	});
}

// setViewPortPosition Grund: siehe TUB-8110 // TUB- TUB-8342
// bezieht sich auf alte PDS und PLS Filter und ist nicht mehr relevant
const _setViewportPosition = function (header) {
	if (Modernizr.mq(breakpoints["m_down"]) && header.next().css("display") === "block") {
		$("html,body").animate({ scrollTop: header.offset().top - headerNav.outerHeight() }, 300);
	}
};

const setAccordion = function (accordionHeader, accordionContent, setViewPortPosition = false) {
	if (!(accordionContent.length || accordionHeader.length)) {
		return;
	}

	accordionHeader.off("click").click(function () {
		const $thisHeader = $(this);
		accordionHeader.removeClass("is-open");
		accordionHeader.attr("aria-expanded", "false");
		accordionContent.not(":hidden").stop(true, true).slideUp(400);
		$thisHeader
			.next(accordionContent)
			.not(":visible")
			.slideDown(400, () => {
				if (setViewPortPosition) {
					_setViewportPosition($thisHeader);
				}
			})
			.prev()
			.addClass("is-open")
			.attr("aria-expanded", "true");
	});
};

export { init, setAccordion };
