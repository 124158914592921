const init = function () {
	handleViewportChange();
	["scroll", "resize"].forEach((event) => {
		window.addEventListener(event, handleViewportChange);
	});
};

const handleViewportChange = () => {
	if (matchMedia("(min-width: 64em)").matches) {
		document.documentElement.classList.remove(
			"is-logo-collapsed",
			"is-site-links-fading-out",
			"is-site-links-faded-out"
		);
		return;
	}
	const userFontSize = Number(
		window.getComputedStyle(document.documentElement, null).getPropertyValue("font-size").replace("px", "")
	);
	const rootFontSize = 14;
	const fontSizeMultiplier = userFontSize / rootFontSize;
	const logoCollapseOffset = 52;
	const landingPageLogoCollapseOffset = 24;
	const siteLinksFadeOutOffset = 4;
	const scrollPos = window.scrollY;
	const isSmallHeader =
		document.documentElement.classList.contains("is-landingpage") ||
		document.documentElement.classList.contains("is-checkout") ||
		document.body.classList.contains("page-ContactPage");

	const logoOffset = isSmallHeader ? landingPageLogoCollapseOffset : logoCollapseOffset;

	if (scrollPos > logoOffset * fontSizeMultiplier) document.documentElement.classList.add("is-logo-collapsed");
	else document.documentElement.classList.remove("is-logo-collapsed");

	if (scrollPos > siteLinksFadeOutOffset * fontSizeMultiplier)
		document.documentElement.classList.add("is-site-links-fading-out");
	else document.documentElement.classList.remove("is-site-links-fading-out");
};

export { init };
