/**
 * GTM Banderole
 * see TUB-22323 for usage example
 */
import OcmMessenger from "ocm_mercurius_messenger";
import { messengerPublicationTypes } from "@ocm/services/services.constants";
import { pushGA4CustomEvent } from "@ocm/services/analytics4Tracking";
import { showModal } from "./infoModals";

const banderoleEl = document.getElementById("js-banderole");
const cartBanderoleEl = document.getElementById("js-cart-banderole");

OcmMessenger.subscribe(messengerPublicationTypes.ROUTE_CHANGE_MOUNTED_APPS, ({ data: { mountedApps } }) => {
	if (mountedApps.includes("mf-cart")) {
		_hide(banderoleEl);
		_slideIn(cartBanderoleEl);
		return;
	}
	_hide(cartBanderoleEl);
	_slideIn(banderoleEl);
});

function _isCartPage() {
	return window.location.pathname.includes("/cart");
}

/** @param {HTMLElement} el */
function _hide(el) {
	el.style.display = "";
	el.classList.remove("slided-in");
}

/** @param {HTMLElement} el */
function _slideIn(el) {
	if (!el.hasChildNodes()) return;

	el.style.display = "block";
	requestAnimationFrame(() => el.classList.add("slided-in"));
}

/*
interface show {
	content: Array<string | {
		text: string
		bold?: boolean = false
		voucher?: boolean = false
	}>,
	modal?: {
		headline?: string
		text: string
	},
	url?: string
	color?: "black" | "red" | "darkBlue" | "ebony" | "lightBlue" | "smokyQuartz" | "actionColor" | "champagne" | "gold" | "pearl" | "silver"
	position?: "top"
}
*/

/**
 * @typedef {object} BanderoleContent
 * @property {string} text
 * @property {boolean} [bold]
 * @property {boolean} [voucher]
 *
 * @typedef {object} ModalContent
 * @property {string} [headline]
 * @property {string} text
 */

/**
 * @param {object} params
 * @param {Array<string | BanderoleContent>} params.content
 * @param {ModalContent} [params.modal]
 * @param {string} [params.url]
 * @param {string} [params.color]
 * @param {string} [params.position]
 */
function show({ content, modal, url, color, position }) {
	const modalId = modal?.text ? "js-banderole-modal" : null;
	banderoleEl.innerHTML = _setBanderoleContent(content, url, modalId);
	_attachEventListeners(banderoleEl);

	if (modalId && !modal.headline) {
		const hasVoucher = content.some((item) => item.voucher);
		modal.headline = hasVoucher ? "Gutscheinbedingungen" : "Aktionsbedingungen";
	}
	_setModalContent(modalId, modal);

	banderoleEl.classList.add(..._getTheme(color));
	_setBanderolePosition(position);
	_isCartPage() ? _hide(banderoleEl) : _slideIn(banderoleEl);
}

/**
 * @param {object} params
 * @param {Array<string | BanderoleContent>} params.content
 * @param {ModalContent} [params.modal]
 * @param {string} [params.url]
 */
function showCart({ content, modal, url }) {
	const modalId = modal?.text ? "js-cart-banderole-modal" : null;
	cartBanderoleEl.innerHTML = _setBanderoleContent(content, url, modalId);
	_attachEventListeners(cartBanderoleEl);
	_setModalContent(modalId, modal);
	_slideIn(cartBanderoleEl);
}

/**
 * @param {Array<string | BanderoleContent>} content
 * @param {string} [url]
 * @param {string | null} modalId
 */
function _setBanderoleContent(content, url, modalId) {
	const HTMLContent = content
		.map((item) => {
			if (typeof item === "string") return item;

			if (item.voucher) {
				return `
					<button
						type="button"
						class="js-banderole-copy-button c-banderole-copy-button"
						data-voucher-code="${item.text}"
					>
						${item.text}
						<svg class="svg-icon size-4" aria-hidden="true"><use href="#icon-copy"></use></svg>
					</button>`;
			}

			return item.bold ? `<strong class="font-semibold">${item.text}</strong>` : item.text;
		})
		.join(" ");

	const accessibleLink = url ? `<a href="${url}" class="js-banderole-link sr-only">zur Aktion</a>` : "";

	if (!modalId) return `<div class="o-page-wrap py-3">${HTMLContent}</div> ${accessibleLink}`;

	return `
		<div class="o-page-wrap c-banderole-grid-content py-3">
			<div>${HTMLContent}</div>
			<button
				type="button"
				class="js-banderole-modal-button c-banderole-modal-button"
				data-modal-id="${modalId}"
				aria-label="Hinweis anzeigen"
			>
				<svg class="svg-icon size-4" aria-hidden="true"><use href="#icon-info"></use></svg>
			</button>
		</div>
		${accessibleLink}`;
}

/**
 * @param {string | null} modalId
 * @param {ModalContent} [modalContent]
 */
function _setModalContent(modalId, modalContent) {
	if (!modalId || !modalContent?.text) return;

	const modalEl = document.getElementById(modalId);
	modalEl.querySelector(".info-modal__header").innerText = modalContent.headline ?? "Hinweis";
	modalEl.querySelector(".info-modal__body").innerText = modalContent.text;
}

/** @param {HTMLElement} el */
function _attachEventListeners(el) {
	el.addEventListener("click", _onBanderoleClick);
	el.querySelector(".js-banderole-modal-button")?.addEventListener("click", (e) => {
		e.stopPropagation();
		_openModal(e.currentTarget.dataset.modalId, e.currentTarget);
	});
	el.querySelector(".js-banderole-copy-button")?.addEventListener("click", (e) => {
		e.stopPropagation();
		_copyToClipboard(e.currentTarget.dataset.voucherCode);
	});
}

function _onBanderoleClick({ currentTarget }) {
	const linkEl = currentTarget.querySelector(".js-banderole-link");
	if (!linkEl) return;

	const voucherCode = currentTarget.querySelector(".js-banderole-copy-button")?.dataset.voucherCode ?? "";
	pushGA4CustomEvent("event_generic", {
		event_action: "click",
		event_category: "banderole-link",
		event_label: voucherCode,
	});
	window.location.href = linkEl.href;
}

/**
 * @param {string} modalId
 * @param {HTMLButtonElement} triggerEl
 */
const _openModal = (modalId, triggerEl) => {
	const modal = document.getElementById(modalId);
	if (!modal) return;

	showModal(modal, triggerEl);
};

/** @param {string} voucherCode */
async function _copyToClipboard(voucherCode) {
	if (!voucherCode) return;

	try {
		await navigator.clipboard.writeText(voucherCode);
		OcmMessenger.publish(messengerPublicationTypes.ADD_TOAST, { message: "Gutscheincode wurde kopiert" });
		pushGA4CustomEvent("event_generic", {
			event_action: "copy",
			event_category: "banderole",
			event_label: voucherCode,
		});
	} catch (error) {
		console.error("Writing to clipboard failed", error);
	}
}

/** @param {string} [color] */
// eslint-disable-next-line complexity
function _getTheme(color) {
	const classList = [];
	switch (color) {
		case "black":
		case "red":
			classList.push(`bg-${color}-primary`, "text-white");
			break;
		case "darkBlue":
		case "ebony":
		case "lightBlue":
		case "smokyQuartz":
			classList.push(`bg-${color}`, "text-white");
			break;
		case "actionColor":
		case "champagne":
		case "gold":
		case "pearl":
		case "silver":
			classList.push(`bg-${color}`);
			break;
		default:
			classList.push("bg-gray-20", "border-b-gray-40");
			break;
	}

	return classList;
}

/** @param {string} [position] */
function _setBanderolePosition(position) {
	if (position !== "top") return;

	const headerEl = document.getElementById("js-header");
	headerEl.before(banderoleEl);
	banderoleEl.classList.add("is-top");
	_adjustHeaderLogoAnimation();
	window.addEventListener("resize", _adjustHeaderLogoAnimation);
}

const headerLogoEl = document.getElementById("c-logo__container");
const shopHomeButtonEl = document.getElementById("shop-home-button");
let hasScrollListener = false;
function _adjustHeaderLogoAnimation() {
	if (!matchMedia("(min-width: 64em)").matches) {
		banderoleEl.style.display = "block";
		headerLogoEl.style.top = shopHomeButtonEl.style.top = `${banderoleEl.offsetHeight}px`;
		document.addEventListener("scroll", _setLogoTopPosition);
		hasScrollListener = true;
	} else if (hasScrollListener) {
		document.removeEventListener("scroll", _setLogoTopPosition);
		hasScrollListener = false;
		headerLogoEl.style.top = shopHomeButtonEl.style.top = 0;
	}
}

function _setLogoTopPosition() {
	if (window.scrollY <= banderoleEl.offsetHeight) {
		headerLogoEl.style.top = shopHomeButtonEl.style.top = `${banderoleEl.offsetHeight - window.scrollY}px`;
	} else {
		headerLogoEl.style.top = shopHomeButtonEl.style.top = 0;
	}
}

export { show, showCart };
