import * as utils from "@sharedJS/utils";

import * as myAccountOffCanvasHeadline from "@sharedJS/myAccountOffCanvasHeadline";
import { inertLandmarksAndSkipLinks, removeInertFromLandmarksAndSkipLinks } from "@ocm/services/services.inert";
import * as storage from "@sharedJS/storage";

//Dom
let $offCanvasWrapper;
let $offCanvasNavigation;
let $offCanvasMyAccount;

// State and Cookie-Storage
let offCanvasIsOpen;
const openOffcanvasCookieName = "openOffcanvas"; // used for left OffCanvas

// MyAccount
let $myAccountNavContent;

let previouslyFocusedElement;
let $openNavigationBtn;
let $openMyAccountBtn;

function init() {
	offCanvasIsOpen = false;

	$offCanvasNavigation = $(".js-offcanvas-left");
	$offCanvasMyAccount = $(".js-offcanvas-right");
	if (!$offCanvasNavigation.length && !$offCanvasMyAccount.length) return;
	$offCanvasWrapper = $(".js-offcanvas-wrapper");

	_bindActionsToDomElements();
	_initNavigation();
	_initMyAccount();
}

function _bindActionsToDomElements() {
	$openNavigationBtn = $(".js-left-trigger");
	$openMyAccountBtn = $(".js-my-account-offcanvas-button");
	const $offCanvasClose = $(".js-offcanvas-close");

	$openNavigationBtn.on("click", _openNavigation); // left off-canvas = Navigation
	$openMyAccountBtn.on("click", _openMyAccountNav); // right off-canvas = MyAccount
	$offCanvasClose.on("click", _closeCanvas); // bind closeOffCanvas

	// Close on Browser Back
	addEventListener("popstate", _closeCanvas);

	_bindCanvasResize();
}

function _bindCanvasResize() {
	addEventListener("resize", () => {
		if (
			!document.querySelector(".js-offcanvas-wrapper.is-left-offcanvas") &&
			!document.querySelector(".js-offcanvas-wrapper.is-right-offcanvas")
		)
			return;
		if (matchMedia("(min-width: 64em)").matches) {
			_closeCanvas();
		}
	});
}

function _initNavigation() {
	_navigationManipulateLinks(); // Navigation
	// open Navigation onPageLoad?
	const initCanvas = storage.getCookie(openOffcanvasCookieName);
	if (initCanvas === "left") {
		_openNavigation();
	}
	storage.deleteCookie(openOffcanvasCookieName);
}

function _initMyAccount() {
	$myAccountNavContent = $("#my-account-canvas");
	$myAccountNavContent.hide().removeClass("u-hide");
}

function _navigationManipulateLinks() {
	const transitionDuration = 400;
	$offCanvasNavigation[0]?.style.setProperty("--navTransitionDuration", `${transitionDuration}ms`);

	const $burgerSlides = $offCanvasNavigation.find(".js-offcanvas-nav");
	const $burgerLinks = $burgerSlides.find("button[data-nav], a[data-nav]"); // Links zum nächsten Slide

	$burgerSlides.find("a[onclick]").removeAttr("onclick").attr("data-orchestrator", "1");
	// Hinweis: ist vermutlich ok auch wenn seoLinkMask / js-dynamicLink aktiv ist, da der Orchestrator dann vermutlich nicht greift
	// jedenfalls wird der maskierte Link trotzdem aufgerufen.

	$burgerSlides
		.find("a[href]:not([data-nav], [data-department])")
		.removeAttr("onclick")
		.click((event) => {
			_closeCanvas();
			// if is Orchestrator Link
			if (window.ocmOrchestrator && event.currentTarget.hasAttribute("data-orchestrator")) {
				event.preventDefault();
				// timeout neccessary to make history change happen ( _closeCanvas() )
				window.setTimeout(() => {
					window.ocmOrchestrator && window.ocmOrchestrator.spaNavigateUrl(event);
				}, 1);
			}
		});

	$burgerSlides.find("a[data-department]").click(() => {
		_removeHistoryEntry();
		storage.setCookie(openOffcanvasCookieName, "left");
	});

	// SPA-Handler deaktivieren für Links innerhalb des Burgers
	$burgerLinks.removeAttr("onclick");

	// Burger-Navigation
	$burgerLinks.on("click", function (e) {
		const $link = $(this);
		const $currentSlide = $link.closest($burgerSlides);
		const targetId = $link.data("nav");
		const $targetSlide = $burgerSlides.filter(`#${targetId}`);
		const isNext = !!$currentSlide.nextAll(`#${targetId}`).length;

		if (!$targetSlide.length) return;
		e.preventDefault();

		if (isNext) $targetSlide.addClass("js-nav-next");
		$targetSlide.show();
		_navigationScrollSlideToTop($targetSlide);

		requestAnimationFrame(() => {
			$currentSlide.removeClass("js-nav-active").addClass(isNext ? "" : "js-nav-next");
			$targetSlide.addClass("js-nav-active").removeClass("js-nav-next");

			setTimeout(() => {
				$currentSlide.hide().removeClass("js-nav-next");
			}, transitionDuration);
		});

		const $closestDialog = _getClosestDialog($link);
		const SAFETY_DELAY = 50;
		setTimeout(() => {
			_focusFirstElement($closestDialog);
			_announceHeadline($closestDialog);
		}, transitionDuration + SAFETY_DELAY);
	});
}

function _announceHeadline($dialog) {
	const $statusElement = $dialog.find('[role="status"]');
	let $currentHeadline = $dialog.find("h1, h2, h3, h4, h5, h6").not(".sr-only").filter(":visible").first();
	if (!$currentHeadline.length) $currentHeadline = $dialog.find("h1, h2, h3, h4, h5, h6").filter(".sr-only").first();
	$statusElement.text($currentHeadline.text());
}

function _navigationShowFirstSlide() {
	const $burgerSlides = $offCanvasNavigation.find(".js-offcanvas-nav");
	$burgerSlides.removeClass("js-nav-active").hide();
	$burgerSlides.first().addClass("js-nav-active").show();
	_navigationScrollSlideToTop($burgerSlides.first());
}

function _navigationScrollSlideToTop($slide) {
	const burgerNavigationMainClass = ".js-offcanvas-nav-main"; // Navigation Scrollable Part
	$slide.find(burgerNavigationMainClass).scrollTop(0);
}

// Navigation == LeftCanvas
function _openNavigation() {
	// TUB-20712: Quick fix to block Login Layer on Brand Overview Page
	if (document.getElementById("js-login-interrupt") && !window.location.pathname.split("/").includes("marken")) return;
	_openCanvas("is-left-offcanvas", $(".js-left-scrollable"), $(".js-left-scrollable-inner"));
	utils.removeIOSRubberEffect(".js-left-scrollable");
	$openNavigationBtn?.attr("aria-expanded", "true");
}

// MyAccount == RightCanvas
function _openMyAccountNav() {
	$myAccountNavContent.show();
	if ($(".is-right-offcanvas").length === 0) {
		myAccountOffCanvasHeadline.fillMyAccountOffCanvasHeadline();
	}
	_openCanvas("is-right-offcanvas", $(".js-offcanvas-right"), $(".js-right-scrollable-inner"));
	utils.removeIOSRubberEffect(".js-right-scrollable");
	$openMyAccountBtn?.attr("aria-expanded", "true");
}

function _getClosestDialog($element) {
	return $element.closest('[role="dialog"]');
}

function _focusFirstParentDialog($element) {
	const $dialog = _getClosestDialog($element);
	if ($dialog.length) $dialog.focus();
}

function _focusFirstElement($element) {
	const focusableElements = $element.find("a, button, input, textarea, select").filter(":visible");
	const firstElement = focusableElements.first();
	firstElement.focus();
}

function _handleEscKey(event) {
	if (event.key === "Escape") {
		_closeCanvas();
	}
}

function _openCanvas(canvas, $scrollableBox, $scrollableInner) {
	previouslyFocusedElement = document.activeElement;

	offCanvasIsOpen = true;
	_addHistoryEntry();
	$offCanvasWrapper.addClass(canvas);

	const scrollableBoxHeight = $scrollableBox.innerHeight();
	const scrollableInnerHeight = $scrollableInner.height();
	if (scrollableBoxHeight >= scrollableInnerHeight) {
		$scrollableInner.css({ minHeight: scrollableBoxHeight });
	}
	utils.disableScrolling(true);
	$scrollableInner.focus();

	_focusFirstParentDialog($scrollableInner);
	document.addEventListener("keydown", _handleEscKey);
	inertLandmarksAndSkipLinks($scrollableInner[0]);
}

function _closeCanvas() {
	if (offCanvasIsOpen === false) return;

	offCanvasIsOpen = false;
	const isClosingLeftCanvas = $offCanvasWrapper.hasClass("is-left-offcanvas");
	const isClosingRightCanvas = $offCanvasWrapper.hasClass("is-right-offcanvas");
	$offCanvasWrapper.one("transitionend", _navigationShowFirstSlide);

	utils.disableScrolling(false);
	_removeHistoryEntry();

	document.removeEventListener("keydown", _handleEscKey);

	if (previouslyFocusedElement) {
		previouslyFocusedElement.focus();
		previouslyFocusedElement = null;
	}

	removeInertFromLandmarksAndSkipLinks();

	if (isClosingLeftCanvas) {
		$offCanvasWrapper.removeClass("is-left-offcanvas");
		$openNavigationBtn?.attr("aria-expanded", "false");
		$openNavigationBtn?.focus();
	}
	if (isClosingRightCanvas) {
		$offCanvasWrapper.removeClass("is-right-offcanvas");
		$openMyAccountBtn?.attr("aria-expanded", "false");
		$openMyAccountBtn?.focus();
	}
}

// manipulate history
function _addHistoryEntry() {
	history.pushState({ isOffcanvasOpen: true }, "", "");
}

function _removeHistoryEntry() {
	if (history.state?.isOffcanvasOpen) {
		history.back();
	}
}

// public
function myAccountOffCanvasButtonToggle() {
	_closeCanvas();
}

export { init, myAccountOffCanvasButtonToggle };
