const LANDMARK_ELEMENTS_SELECTOR = "header, main, footer, nav, aside, [role='search']";
const USABILLA_SELECTOR = "#ocmCustomFeedback";
let inertedElements = [];

/** @type {HTMLElement | null} */
const mainEl = document.querySelector("#js-main-container");
/** @type {HTMLElement | null} */
const skipLinksEl = document.querySelector("#js-skip-links");

/**
 * @param {HTMLElement | null} currentElementNotToInert - Any HTML element that should stay active. Leaves the Landmark active that contains or is part of this element.
 */
export function inertLandmarksAndSkipLinks(currentElementNotToInert) {
	_addInertToLandmarks(currentElementNotToInert);
	_addInertToSkipLinks();
	_inertUsabillaFeedback();
}

export function removeInertFromLandmarksAndSkipLinks() {
	_removeInertFromLandmarks();
	_removeInertFromSkipLinks();
	_removeInertUsabillaFeedback();
}

export function inertMainContainerAndSkipLinks() {
	_addInertToMainContainer();
	_addInertToSkipLinks();
	_inertUsabillaFeedback();
}

export function removeInertFromMainContainerAndSkipLinks() {
	_removeInertFromMainContainer();
	_removeInertFromSkipLinks();
	_removeInertUsabillaFeedback();
}

// Private functions

/**
 * @param {HTMLElement | null} currentElementNotToInert
 */
function _addInertToLandmarks(currentElementNotToInert) {
	inertedElements = Array.from(document.querySelectorAll(LANDMARK_ELEMENTS_SELECTOR)).filter(
		(element) => !element.contains(currentElementNotToInert) && !currentElementNotToInert.contains(element)
	);
	inertedElements.forEach((element) => (element.inert = true));
}

function _removeInertFromLandmarks() {
	if (!inertedElements) return;
	inertedElements.forEach((element) => (element.inert = false));
	inertedElements = [];
}

function _addInertToMainContainer() {
	if (mainEl) mainEl.inert = true;
}

function _removeInertFromMainContainer() {
	if (mainEl) mainEl.inert = false;
}

function _addInertToSkipLinks() {
	if (skipLinksEl) skipLinksEl.inert = true;
}

function _removeInertFromSkipLinks() {
	if (skipLinksEl) skipLinksEl.inert = false;
}

function _inertUsabillaFeedback() {
	const usabillaFeedbackEl = document.querySelector(USABILLA_SELECTOR);
	if (usabillaFeedbackEl) usabillaFeedbackEl.inert = true;
}

function _removeInertUsabillaFeedback() {
	const usabillaFeedbackEl = document.querySelector(USABILLA_SELECTOR);
	if (usabillaFeedbackEl) usabillaFeedbackEl.inert = false;
}
