import * as utils from "@sharedJS/utils";
let done = false;

const fillMyAccountOffCanvasHeadline = function () {
	if (done) return;
	$.ajax({
		type: "GET",
		dataType: "html",
		url: utils.getUrl("/canvas/myAccountHeadline/"),
		cache: false,
	})
		.done((data) => {
			$(".js-my-account-canvas-header").replaceWith(data);
		})
		.fail(() => $(".js-my-account-canvas-header").remove())
		.always(() => (done = true));
};

export { fillMyAccountOffCanvasHeadline };
