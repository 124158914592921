import * as fixedBar from "@classicJS/fixedBar";
import breakpoints from "@sharedJS/breakpoints";

// DOM
let $brandItems;
let $brandListButtons;
let $brandListButton;
let $brandListSearch;
let $brandListLetter;
let $brandListButtonWrapper;
let $brandListReserveSpace;
let $headerNav;
let $window;

// Dependencies
let fixedBarInitialized = false;

function init() {
	$brandItems = $(".js-brandlist-branditem");
	$brandListButtons = $(".js-brandlist-buttons");
	$brandListButton = $(".js-brandlist-button");
	$brandListSearch = $(".js-brandlist-search");
	$brandListLetter = $(".js-brandlist-letter");

	$brandListButtonWrapper = $(".js-brandlist-button-wrapper");
	$brandListReserveSpace = $(".js-brandlist-reserve-space");
	$headerNav = $(".js-header-nav");
	$window = $(window);

	_toggleFixedABC();

	$window.resize(() => {
		_toggleFixedABC();
	});

	// Bind Actions
	$brandListButton.each(function () {
		$(this).on("click", function (event) {
			event.preventDefault();
			const letterLink = $(this).attr("href");
			const navigationOffSet = $brandListButtonWrapper.height() + $headerNav.height();

			if (!$(this).hasClass("is-disabled")) {
				const contentLetter = ($(letterLink).offset() || { contentLetter: NaN }).top;
				if (!isNaN(contentLetter)) {
					$("html, body").animate(
						{
							scrollTop: contentLetter - navigationOffSet,
						},
						"slow"
					);
				} else {
					return;
				}
			}
		});
	});

	$brandListButton.on("click", function () {
		if (!$(this).hasClass("is-disabled")) {
			$brandListButton.removeClass("is-active");
			$(this).addClass("is-active");
			$brandListLetter.removeClass("is-active");
			const letter = $(this).attr("href");
			const item = $(letter).find(".js-brandlist-letter");
			item.addClass("is-active");
		} else {
			return false;
		}
	});

	// Scroll-Buttons
	$brandListButtons.scrollbar({ showArrows: true, scrollx: "advanced", scrolly: "advanced" });

	// Brands Search
	$brandListSearch.on("keyup", function () {
		const valThis = this.value.toLowerCase();
		toggleClearIcon($brandListSearch);
		viewItemBySearch(valThis);
	});

	$brandListSearch
		.parent()
		.find(".js-clear-btn")
		.on("click", () => {
			const inputVal = $brandListSearch.val().toLowerCase();
			viewItemBySearch(inputVal);
			toggleClearIcon($brandListSearch);
		});

	$(".js-brandlist-searchbox").on("click", () => {
		$brandListSearch[0].focus();
	});
}

//fixed ABC-List
function _toggleFixedABC() {
	if ($brandListButtonWrapper.length) {
		let brandListHeight = $brandListButtonWrapper.offset().top + $brandListButtonWrapper.height() - $headerNav.height();
		const brandListArray = [$brandListButtonWrapper, $brandListReserveSpace];
		if (Modernizr.mq(breakpoints["m_down"])) {
			brandListHeight = $brandListButtonWrapper.offset().top;
		}

		if (fixedBarInitialized == false) {
			fixedBarInitialized = true;
			fixedBar.setFixedBar(brandListHeight, brandListArray, $brandListButtonWrapper);
		}
	}
}

function viewItemBySearch(valThis) {
	$brandItems.each(function () {
		const text = $(this).find("a").text().toLowerCase();
		if (text.indexOf(valThis) !== -1) {
			$(this).removeClass("is-notInResult");
		} else {
			$(this).addClass("is-notInResult");
		}
	});

	if (valThis.length > 0) {
		$(".js-brandlist").addClass("is-activeSearch");
		$brandListButtonWrapper.addClass("is-activeSearch");
	} else {
		$(".js-brandlist").removeClass("is-activeSearch");
		$brandListButtonWrapper.removeClass("is-activeSearch");
	}
}

function toggleClearIcon($element) {
	if ($element.val()) {
		$element.parent().find(".js-clear-btn").removeClass("is-hidden");
	} else {
		$element.parent().find(".js-clear-btn").addClass("is-hidden");
	}
}

export { init };
