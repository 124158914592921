import OcmMessenger from "ocm_mercurius_messenger";
import { messengerPublicationTypes } from "@ocm/services/services.constants";
import stickyHeader from "@sharedJS/stickyHeader";

/**
 * @type {HTMLElement | null}
 */
let headerNavEl = null;
const _defaultHeaderNavHeight = 46;
let boundCloseWhenFocusIsOutside = null;
let boundCloseOnEscPress = null;

const DEPARTMENT_LIST_SELECTOR = ".js-primary-nav-toggle";
const DEPARTMENT_NAV_CONTAINER = ".js-primary-nav-container";

const init = function () {
	// todo: newheader -> pageType anders ermitteln (siehe auch scss)

	$(".js-nav-login-button").on("click", (e) => {
		const _selectedLayerName = $("#master-common-layer").data("layerselected") || null;
		if (_selectedLayerName) {
			e.preventDefault();
			OcmMessenger.publish(messengerPublicationTypes.REQUEST_LOGIN, {
				isClosable: true,
			});
		}
	});

	headerNavEl = document.querySelector(".js-header-nav");

	stickyHeader.setHelperClasses();

	_onHeaderNavHeightChanged();

	const departmentNavContainer = document.querySelector(DEPARTMENT_NAV_CONTAINER);
	const departmentNavButton = departmentNavContainer?.querySelector("button");
	const departmentList = document.querySelector(DEPARTMENT_LIST_SELECTOR);

	if (departmentNavButton) {
		departmentNavButton.addEventListener("click", (event) =>
			_toggleDepartmentNavOpen(departmentNavButton, departmentList, event)
		);
	}

	if (departmentNavContainer) {
		departmentNavContainer.addEventListener("mouseenter", () => {
			_openDepartementNav(departmentNavButton, departmentList);
		});
		departmentNavContainer.addEventListener("mouseleave", () => {
			_closeDepartementNav(departmentNavButton, departmentList);
		});
	}

	const mediaQuery = window.matchMedia("(min-width: 64em)");
	if (mediaQuery.addEventListener) {
		mediaQuery.addEventListener("change", _onHeaderNavHeightChanged);
	}
};

function _toggleDepartmentNavOpen(departmentNavButton, departmentList, event) {
	event.stopPropagation(); // stop login layer from opening
	if (departmentList.classList.contains("is-open")) {
		_closeDepartementNav(departmentNavButton, departmentList);
	} else {
		_openDepartementNav(departmentNavButton, departmentList);
	}
}

function _openDepartementNav(departmentNavButton, departmentList) {
	if (window.getComputedStyle(departmentList).display !== "none") return; // already open, for example always above 1400px
	departmentList.classList.add("is-open");
	addOnFocusChangeCloseListener(departmentNavButton, departmentList);
	departmentNavButton.setAttribute("aria-expanded", "true");
	boundCloseOnEscPress = _closeOnEscPress.bind(this, departmentNavButton, departmentList);
	document.addEventListener("keydown", boundCloseOnEscPress);
}

function _closeDepartementNav(departmentNavButton, departmentList) {
	if (!departmentList.classList.contains("is-open")) return; // not expanded, for example always above 1400px
	departmentList.classList.remove("is-open");
	removeOnFocusChangeCloseFlyoutListener();
	departmentNavButton.setAttribute("aria-expanded", "false");
	document.removeEventListener("keydown", boundCloseOnEscPress);
}

function addOnFocusChangeCloseListener(departmentNavButton, departmentList) {
	boundCloseWhenFocusIsOutside = _closeWhenFocusIsOutside.bind(this, departmentNavButton, departmentList);
	document.addEventListener("focusin", boundCloseWhenFocusIsOutside);
}

function removeOnFocusChangeCloseFlyoutListener() {
	document.removeEventListener("focusin", boundCloseWhenFocusIsOutside);
}

function _closeWhenFocusIsOutside(departmentNavButton, departmentList, event) {
	if (departmentList && !departmentList.contains(event.target)) {
		_closeDepartementNav(departmentNavButton, departmentList);
	}
}

function _closeOnEscPress(departmentNavButton, departmentList, event) {
	if (event.key === "Escape" && departmentList.classList.contains("is-open")) {
		_closeDepartementNav(departmentNavButton, departmentList);
		departmentNavButton.focus();
	}
}

const getHeaderNavHeight = () => headerNavEl?.offsetHeight ?? _defaultHeaderNavHeight;

const _onHeaderNavHeightChanged = () => {
	const headerNavHeight = getHeaderNavHeight();
	document.documentElement.style.setProperty("--header-nav-height", headerNavHeight);
	OcmMessenger.publish(messengerPublicationTypes.HEADER_NAV_HEIGHT_CHANGED, { headerNavHeight });
};

const header = {
	init,
};

export default header;
// todo: newheader
